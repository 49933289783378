
<section class="box contenedor-editor">

    <h4 class="title is-4" *ngIf="detalle">{{ detalle.titulo }}</h4>
    <app-datos-usuario></app-datos-usuario>

    <jodit-editor class="editor" #elemento [config]="{buttons: 'bold, strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,|,hr,about,source'}"></jodit-editor>

    <section class="contenedor-botones">

        <button class="button is-link is-outlined" (click)="actualizarContenido()">Guardar</button>

        <button class="button is-link is-outlined" [routerLink]="['/listar']">Listar Páginas</button>

    </section>

</section>
