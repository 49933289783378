import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public apiUrl: string = '';

  constructor(
    private _http: HttpClient
  ) { 
    this.apiUrl = environment.url;
  }

  iniciarLogin(credenciales): Observable<any> {
    let datos = JSON.stringify(credenciales);
    let url   = `${this.apiUrl}/index.php/login`;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(url, datos, { headers: headers }); 
  }

}
