import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UsuariosService } from 'src/app/servicios/usuarios.service';

@Component({
  selector: 'app-datos-usuario',
  templateUrl: './datos-usuario.component.html',
  styleUrls: ['./datos-usuario.component.css']
})
export class DatosUsuarioComponent implements OnInit {

  public nombre: string;

  constructor(
    private _router: Router,
    private _usuario: UsuariosService
  ) { }

  ngOnInit(): void {
    this._usuario.getLocalStorege();
    this.nombre = this._usuario.nombre;
  }

  cerrarSesion() {
    this._usuario.removeLocalStorage();
    this._router.navigate([`/login`]);
  }

}
