import { Component, OnInit } from '@angular/core';
import { ITEM_CLASS } from 'jodit/src/modules/filebrowser/consts';

import { AutenticacionService } from 'src/app/servicios/autenticacion.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { ContenidoService } from '../../servicios/contenido.service';


@Component({
  selector: 'app-listar-contenidos',
  templateUrl: './listar-contenidos.component.html',
  styleUrls: ['./listar-contenidos.component.css']
})
export class ListarContenidosComponent implements OnInit {

  public contenidos: any[];

  constructor(
    private _contenidoServicio: ContenidoService,
    private _autenticacionServicio: AutenticacionService,
    private _usuarioServicio: UsuariosService
  ) { }

  ngOnInit(): void {
    console.log("token", this._usuarioServicio.token);//mejorar creo que algo esta fallando validar mejor el token, ya que me esta dejando entrar al darle atrz despues de cerrar la sesión

    // if(this._usuarioServicio.token == undefined || this._usuarioServicio.token == "null") {
    //   this._autenticacionServicio.modificarLoginFalse();
    //   console.log("Entro al if");
    // }else{
    //   this.listarContenidos();
    // }

    this.listarContenidos();

  }

  public listarContenidos() {

    this._contenidoServicio.listarContenidos()
        .subscribe(
            res => {

              this.filtrarPorAnio(res.datos);

            },
            err => {
                console.log("err", err);
            },
            () => console.log('listarContenidos() Finalizo')
        );

  }

  filtrarPorAnio(datos) {

      this.contenidos = datos.filter( item  => {

        return item.anio == '2023';

      });

      console.log("this.contenidos", this.contenidos);

  }


}
