<section class="box contenedor-tabla">

    <h4 class="title is-4">Listado de las páginas</h4>
    <app-datos-usuario></app-datos-usuario>
    <hr>

    <table class="table is-hoverable is-fullwidth">
        <thead>
        <tr>
            <th>#</th>
            <th>Titulo</th>
            <th>Resumen del Contenido</th>
            <th>Acciones</th>
        </tr>
        </thead>

        <tbody>

            <tr *ngFor="let contenido of contenidos; let i = index">
                
                <td>{{ i + 1 }}</td>
                <td>{{ contenido.titulo }}</td>
                <td [innerHTML]="contenido.contenido | slice:0:120"></td>
                <td>
                     <a [routerLink]="['/detalle', contenido.id]">Editar</a>
                </td>
            </tr>

        </tbody>

    </table>

</section>