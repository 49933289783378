import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {

  private login: boolean;

  constructor() { 
    this.login = false;
  }

  modificarLoginTrue() {
    this.login = true;
  }

  // modificarLoginFalse() {
  //   this.login = false;
  // }

  estaLogueado() {
    return this.login
  }

}
