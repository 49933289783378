import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ContenidoService {

  private apiUrl: string;
  private token: string = '';

  constructor(
    private _http: HttpClient
  ) { 
    this.apiUrl = environment.url;
    this.token = localStorage.getItem('token');
  }

  guardarContenido(contenido): Observable<any> {
    let datos = JSON.stringify(contenido);
    let url   = `${this.apiUrl}/index.php/editores/crear`;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(url, datos, { headers: headers }); 
  }

  listarContenidos(): Observable<any> {
    let url   = `${this.apiUrl}/index.php/editores`;
    let headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.token);
    return this._http.get(url, { headers: headers }); 
  }

  detalleContenido(id): Observable<any> {
    let datos = JSON.stringify(id);
    let url   = `${this.apiUrl}/index.php/editores/detalle/${datos}`;
    let headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.token);
    return this._http.get(url, { headers: headers }); 
  }

  actualizarContenido(contenido): Observable<any> {
    let datos = JSON.stringify(contenido);
    let url   = `${this.apiUrl}/index.php/editores/editar`;
    let headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.token);
    return this._http.put(url, datos, { headers: headers }); 
  }

}
