<section class="box contenedor-login">

    <form class="formulario-login" [formGroup]="formIniciarLogin" (ngSubmit)="iniciarLogin()">

        <div class="field">
            <label class="label">Usuario</label>
            <div class="control">
                <input class="input" type="text" placeholder="e.g usuario" formControlName="usuario">
            </div>
        </div>
              
        <div class="field">
            <label class="label">Contraseña</label>
            <div class="control">
                <input class="input" type="password" placeholder="e.g. ************" formControlName="contrasenia">
            </div>
        </div>

        <div class="control">
            <button class="button is-link is-outlined" type="submit" [disabled]="!formIniciarLogin.valid">Iniciar Sesión</button>
        </div>

    </form>

</section>