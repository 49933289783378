import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ContenidoService } from '../../servicios/contenido.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  @ViewChild("elemento") elemento: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private _contenidoServicio: ContenidoService
  ) { }

  ngOnInit(): void { }

  public guardarDatos() {

    let contenido = this.elementRef.nativeElement.querySelector(".jodit_wysiwyg").innerHTML;

    this._contenidoServicio.guardarContenido(contenido)
        .subscribe(
            res => {
              console.log("res", res);
            },
            err => {
                console.log("err", err);
            },
            () => console.log('guardarContenido() Finalizo')
        );
  }

}
