import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { LoginService } from 'src/app/servicios/login.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { AutenticacionService } from 'src/app/servicios/autenticacion.service';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public formIniciarLogin: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _login: LoginService,
    private _usuario: UsuariosService,
    private _autenticacion: AutenticacionService
  ) { }

  ngOnInit(): void {
    this.camposFormulario();
  }

  camposFormulario() {
    this.formIniciarLogin = this._formBuilder.group({
        usuario: ["", Validators.required],
        contrasenia: ["", Validators.required]
    });
  }

  iniciarLogin() {
    this._login.iniciarLogin(this.formIniciarLogin.value)
        .subscribe( 
            res => {

              if(res.login === false) {
                  Swal.fire('', res.mensaje, 'error');
              }

              if(res.jwt) {
                this._usuario.addLocalStorage(res.datos.usuario.nombre, res.jwt);
                this._autenticacion.modificarLoginTrue();
                this._router.navigate([`/listar`]);
              }
            
        },
        err => {
          console.log(err);
        },
        () => console.log("iniciarLogin() Finalizo")
        )
  }

}
