import { Routes } from '@angular/router';

import { AutenticacionGuard } from './guards/autenticacion.guard';

import { LoginComponent } from './componentes/login/login.component';
//import { EditorComponent } from './componentes/editor/editor.component';
import { ListarContenidosComponent } from './componentes/listar-contenidos/listar-contenidos.component';
import { DetalleComponent } from './componentes/detalle/detalle.component';


export const rutas: Routes = [

  {
      path: '', redirectTo: '/login', pathMatch: 'full'
  },
  {
      path: 'login', component: LoginComponent
  },
  {
      path: 'listar', component: ListarContenidosComponent, canActivate: [AutenticacionGuard]
  },
  {
      path: 'detalle/:id', component: DetalleComponent, canActivate: [AutenticacionGuard]
  }
// ,
//   {
//       path: 'editor', component: EditorComponent
//   }
];