import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute } from '@angular/router'

import { ContenidoService } from '../../servicios/contenido.service';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  @ViewChild("elemento") elemento: ElementRef;

  public detalle: any;
  private datosEnviar: {"contenido": string, "id": number};

  constructor(
    private _activateRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private _contenidoServicio: ContenidoService
  ) { }

  ngOnInit(): void {
    let id = parseInt(this._activateRoute.snapshot.paramMap.get('id'));
    this.verDetalleContenido(id);
    this.datosEnviar = {
        "contenido": "",
        "id": id
    }
  }

  public verDetalleContenido(id) {

    this._contenidoServicio.detalleContenido(id)
        .subscribe(
            res => {
              this.detalle = res.datos;
            },
            err => {
                console.log("err", err);
            },
            () => {
              console.log('verDetalleContenido() Finalizo')
              this.elementRef.nativeElement.querySelector(".jodit_wysiwyg").innerHTML = this.detalle.contenido;
            } 
        );

  }

  actualizarContenido() {

    let contenido = this.elementRef.nativeElement.querySelector(".jodit_wysiwyg").innerHTML;
    this.datosEnviar.contenido = contenido;

    this._contenidoServicio.actualizarContenido(this.datosEnviar)
        .subscribe(
          res => {
            console.log(res.mensaje);
            this.mensajeExitoso(res.mensaje);
          },
          err => {
              console.log("err", err);
              this.mensajeError();
          },
          () => {
            console.log('actualizarContenido() Finalizo')
          } 
      );

  }

  mensajeExitoso(msj) {
    Swal.fire(
      msj,
      '',
      'success'
    )
  }

  mensajeError() {
    Swal.fire(
      '',
      'Se presento un error, inténtalo nuevamente o comuniquese con el área de soporte',
      'error'
    )
  }

}
