import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  public nombre: string;
  public  token: string;

  constructor() { }

  addLocalStorage(nombre, token) {
    localStorage.setItem("nombre", nombre);
    localStorage.setItem("token", token);
  }

  getLocalStorege() {
    this.nombre = localStorage.getItem("nombre");
    this.token = localStorage.getItem("token");
  }

  removeLocalStorage() {
    localStorage.removeItem("nombre");
    localStorage.removeItem("token");
    this.nombre = null;
    this.token = null;
  }

}
