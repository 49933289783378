import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';

import { rutas } from './app.rutas';

import { JoditAngularModule } from 'jodit-angular';

import { AppComponent } from './app.component';
import { EditorComponent } from './componentes/editor/editor.component';
import { ListarContenidosComponent } from './componentes/listar-contenidos/listar-contenidos.component';
import { DetalleComponent } from './componentes/detalle/detalle.component';
import { LoginComponent } from './componentes/login/login.component';
import { DatosUsuarioComponent } from './componentes/datos-usuario/datos-usuario.component';


@NgModule({
  declarations: [
    AppComponent,
    EditorComponent,
    ListarContenidosComponent,
    DetalleComponent,
    LoginComponent,
    DatosUsuarioComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    JoditAngularModule,
    RouterModule.forRoot(rutas)
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
